import { global } from ":global";
import { _Firebase } from "./_model";

export async function addPollAnswer(props: _Firebase.AddPollAnswerProps) {
  const event_id = String(props.eventId);

  return global.fb.rtdb.set({
    path: `/poll/${event_id}/choices/${props.ansIndex}/value`,
    value: props.val,
  });
}
